<template>
  <div class="fixed lg:bottom-2 bottom-14 w-full max-w-5xl mx-auto items-end justify-center py-3 px-4">
    <button
        v-if="collaboration?.status === 'active' && collaboration?.type === 'public' && (status !== 'canceled' || status !== 'completed' || status !== 'expired' || status !== 'ignored')"
        :disabled="loading || collaboration?.closed || accepting"
        @click="ignoreModal = true"
        class="bg-gray focus:outline-none flex justify-center text-white mb-small w-full p-3 rounded-full uppercase disabled:bg-gray">
      <a-loader v-if="loading || ignoring" color="text-white" class="h-6 w-6" />
      <template v-else>
        Ignorar
      </template>
    </button>
    <button
      :disabled="loading || collaboration?.closed || accepting"
      @click="accept"
      :class="{
        'bg-primary': !failed,
        'bg-red-500': failed
      }"
      class="bg-gray focus:outline-none flex justify-center text-white w-full p-3 rounded-full uppercase disabled:bg-gray">
      <a-loader v-if="loading || accepting" color="text-white" class="h-6 w-6" />
      <template v-else>
        {{ collaboration.closed ? 'CERRADA' : failed ? 'Limite alcanzado' : 'COLABORAR' }}
      </template>
    </button>
  </div>

  <a-alert
    title="Colaboración Aceptada"
    :show="modal"
    hide-cancel
    disabled-overlay-dismiss
    @confirm="confirm">
    <div class="space-y-3">
      <h1 class="text-lg text-gray-800">
        Enhorabuena!
      </h1>
      <h1 class="text-lg">
        Gracias por querer colaborar con <b>@{{ collaboration.restaurant?.ig_username }}</b>. Le notificaremos al restaurante.
      </h1>
      <div v-if="collaboration?.type === 'private'">
        <h1 class="text-lg">
          @{{ user.foodie.ig_username.replace('@', '') }} debes indicar en tu reserva el código <b>{{ response.reservation_code }}</b> para garantizar los beneficios de colaboración
        </h1>
        <h1 class="text-lg">
          Llama al número <b class="text-primary"><a :href="`tel:${collaboration?.restaurant?.phone}`"></a>{{ collaboration?.restaurant?.phone }}</b> del restaurante para hacer tu reservación.
        </h1>
      </div>
    </div>
  </a-alert>

  <a-alert
      title="Ignorar colaboracion"
      :show="ignoreModal"
      hide-cancel
      disabled-overlay-dismiss
      @cancel="ignoreModal = false"
      @confirm="ignore">
    <div class="space-y-3">
      <h1 class="text-lg text-gray-800">
        ¿Está seguro que desea ignorar esta colaboración?
      </h1>
    </div>
  </a-alert>

  <a-alert
    title="No se ha podido confirmar la colaboración."
    :show="failed"
    @cancel="failed = false"
    @confirm="failed = false">
    <h1 class="text-lg">
      Has llegado a tu máximo de colaboraciones activas. <br>Publica y valora antes de aceptar nuevas colaboraciones.
    </h1>
  </a-alert>
  <a-alert
      title="Limite de followers requerido"
      :show="failed_min_ig"
      @cancel="failed_min_ig = false"
      @confirm="failed_min_ig = false">
    <h1 class="text-lg">
      No puede aceptar la colaboración ya que no cuenta con la cantidad requerida de followers
    </h1>
  </a-alert>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  emits: ['action-done'],
  data: () => ({
    modal: false,
    ignoreModal: false,
    accepting: false,
    ignoring: false,
    failed: false,
    failed_min_ig: false,
    response: {}
  }),
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    confirm () {
      this.$emit('action-done')
      this.modal = false
    },
    confirmIgnore () {
      this.$emit('action-done')
      this.ignoreModal = false
    },
    accept () {
      this.accepting = !this.accepting
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .accept(this.$route.params.id)
        .then(({ data }) => {
          this.response = {
            reservation_code: data.reservation_code
          }
          this.modal = true
        })
        .catch(err => {
          this.failed = err.response?.data?.message === 'foodie_collab_limit_reached'
          this.failed_min_ig = err.response?.data?.message === 'min_ig_followers_required_batch'
        })
        .finally(() => (this.accepting = !this.accepting))
    },
    ignore () {
      this.ignoring = !this.ignoring
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .setIgnored(this.$route.params.id)
        .then(() => {
          this.confirmIgnore()
          this.$router.push({ path: '/collaborations' })
          // this.ignoreModal = true
        })
        // .catch(err => {
        //   this.failed = err.response?.data?.message === 'foodie_collab_limit_reached'
        // })
        .finally(() => (this.ignoring = !this.ignoring))
    }
  },
  mounted () {
    console.log(this.status)
  }
}
</script>
