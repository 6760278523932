<template>
  <div class="border rounded-lg w-full p-3 text-black font-medium" v-if="!loading">
    <div class="flex flex-col space-y-0.5 text-md text-left">
      <h4 class="text-md font-bold">Requerimientos</h4>
      <p class="text-gray-400 text-justify" >{{ collaboration.requirements }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'collaboration-requirements-card',
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean }
  },
  setup () {}
})
</script>

<style scoped>

</style>
