<template>
  <div class="border rounded-lg w-full p-3 text-black" v-if="!loading">
<!--    <div class="font-medium">-->
<!--      <div class="w-full space-y-1">-->
        <div class="flex justify-between w-full">
          <div class="flex justify-between flex-col text-left">
            <span class="text-xs text-gray-500 leading-tight flex-shrink-0 bg-yellow-400 bg-opacity-50 p-0.5 inline-flex" v-if="collaboration.foodies_meeting">
              FOODIE QUEDADA
            </span>
            <span class="text-xs text-gray-500 leading-tight flex-shrink-0 bg-green-300 bg-opacity-50 p-0.5 inline-flex" v-if="collaboration.type === 'public'">
              PÚBLICA
            </span>
            <h1 class="text-md pt-1">
              <template v-if="isOneDay">
                <span class="flex flex-col items-start leading-tight">
                  <span class="capitalize">
<!--                    {{$filters.upperCaseFirst($filters.intl(new Date(collaboration.start_at), { weekday: 'long' }))}}-->
<!--                    {{$filters.intl(new Date(collaboration?.start_at))}}-->
                    {{$filters.moment(collaboration.start_at, 'dddd DD/MM/YY', true)}}
                  </span>
                  <span v-if="collaboration.foodies_meeting">
<!--                    {{$filters.intl(new Date(collaboration?.end_at), { hour: 'numeric', minute: 'numeric' })}}-->
                    {{$filters.moment(collaboration?.end_at, 'HH:mm', true)}}
                  </span>
                </span>
              </template>
              <template v-else>
                {{
                  `Del ${$filters.moment(collaboration?.start_at, 'DD/MM/YY', true)} al ${
                    $filters.moment(collaboration?.end_at,
                      'DD/MM/YY', true)
                }`}}
              </template>
            </h1>
<!--            <span class="flex flex-col space-y-1 text-left">-->
<!--            <span class="text-xs flex space-x-2">-->
<!--              -->
<!--              <template v-if="collaboration?.allowed_week_days?.length > 6">-->
<!--                Todos los dias-->
<!--              </template>-->
<!--              <template v-else-if="collaboration?.allowed_week_days?.length !== 1">-->
<!--                De lunes a jueves-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                Solo este día-->
<!--              </template>-->
            <div class="font-regular grid grid-cols-7 items-center text-center my-1" style="max-width: 250px; gap: 0.1rem;">
              <div v-for="day in days" :key="day" class="justify-center items-center">
                <div
                    class="flex items-center justify-center days-grid"
                    style="width: 20px; height: 20px; min-width: 20px;"
                    :class="{'bg-onboarding_light_gray text-white rounded-full': collaboration?.allowed_week_days?.includes(day.id)}">
                  <p class="leading-1">
                    {{ day.label }}
                  </p>
                </div>
              </div>
            </div>
<!--            </span>-->
            <span class="space-x-1" v-if="!collaboration.foodies_meeting">
              <i class="icon-user" />
              <span class="text-sm">
                {{ `+${collaboration.companions_limit} acompañantes` }}
              </span>
            </span>
            <span v-else class="text-sm">
              Sin acompañantes
            </span>
<!--            </span>-->
          </div>
          <div class="flex flex-col font-bold text-default-xl justify-between">
            <div v-if="collaboration?.is_premium" class="flex items-center justify-center ml-auto bg-yellow_ribbon rounded-full h-6 w-6">
              <i class="pi pi-star" style="font-size: 12px"></i>
            </div>
            <div>
              <p class="inline-block text-lg">{{ $filters.collaboration.discount(collaboration?.discount_type, collaboration?.discount_value) }}</p>
              <p class="inline-block text-lg" v-if="collaboration?.is_premium">{{ '+' + $filters.currencyFormatter(getCollabPrice) + '€' }}</p>
            </div>
            <p class="text-lg text-right italic text-gray-400 font-normal flex-shrink-0 text-xs">
              {{collaboration.code ? `#${collaboration.code}` : ''}}
            </p>
          </div>
        </div>
<!--        <span class="flex justify-between items-end text-gray-400 font-normal pt-1 w-full">-->
<!--          -->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div v-else class="border rounded-lg w-full p-3 text-black animate-pulse">
    <div class="flex flex-col space-y-2">
      <div class="flex justify-between">
        <div class="h-4 w-16 bg-gray-300 rounded-full" />
        <div class="h-4 w-10 bg-gray-300 rounded-full" />
      </div>
      <div class="h-4 w-48 bg-gray-300 rounded-full" />
      <div class="h-4 w-24 bg-gray-300 rounded-full" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: false },
    isOneDay: { required: false, type: Boolean, default: false }
  },
  setup (props) {
    const getCollabPrice = computed(() => {
      let price = 0

      props.collaboration.products.map(product => {
        price += parseFloat(product.price_e2)
      })

      return price / 100
    })

    const days = ref([
      { label: 'L', id: 1 },
      { label: 'M', id: 2 },
      { label: 'M', id: 3 },
      { label: 'J', id: 4 },
      { label: 'V', id: 5 },
      { label: 'S', id: 6 },
      { label: 'D', id: 7 }
    ])

    return {
      getCollabPrice,
      days
    }
  }
})
</script>
