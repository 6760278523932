<template>
  <div class="flex flex-col pb-32">
    <header class="text-left font-bold border-b border-gray">
      <button @click="$router.go(-1)" class="flex select-none items-center justify-start space-x-2 py-4 px-4 text-black">
        <i class="icon-arrowLeft" />
        <span class="flex flex-col flex-grow items-start">
          <span class="uppercase cursor-pointer font-semibold">
            Atrás
          </span>
        </span>
      </button>
    </header>

    <div class="h-36 bg-gray-300 sm:h-80 w-full relative overflow-hidden flex items-end justify-start">
      <div class="absolute from-black opacity-25 bg-gradient-to-t inset-0 flex items-end justify-start" />
<!--      <div v-if="collaboration?.restaurant?.state" class="cityTag">{{ collaboration.restaurant.state }}</div>-->
      <tag v-if="!loading" :state="collaboration?.restaurant?.state" style="margin-top: 5px;"/>
      <img
        v-if="collaboration?.restaurant?.cover_picture"
        :src="collaboration?.restaurant?.cover_picture"
        :alt="`${collaboration.restaurant.name} profile picture`"
        class="w-full h-full object-cover">
      <img
        v-else
        src="@/assets/restaurant-cover.jpg"
        :alt="`${collaboration?.restaurant?.name} profile picture`"
        class="w-full h-full object-cover">
      <p class="text-default-2xl font-medium text-white p-4 absolute bottom-0 left-0">
        {{ collaboration?.restaurant?.name ?? 'Cargando...' }}
      </p>
    </div>

    <div class="flex flex-col space-y-2 pt-3 px-4">

      <collaboration-description-card
        :is-one-day="isOneDay"
        :collaboration="collaboration"
        :loading="loading" />

      <collaboration-contact-card
          v-if="component === 'accepted-action' || component === 'reserved-action' || component === 'completed-action'"
          :collaboration="collaboration"
          :status="fixedStatus"
          :loading="loading" />

      <collaboration-requirements-card
        v-if="collaboration.requirements"
        :collaboration="collaboration"
        :loading="loading" />

      <div v-if="collaboration.is_premium" class="grid grid-col-1 gap-1 mt-4">
        <div v-for="product in collaboration.products" :key="product">
          <div class="flex border text-onboarding_gray p-2 rounded-md text-left">
            <p class="flex-grow">{{ product.description }}</p>
            <h3 class="font-bold w-14 text-right text-lg text-onboarding_gray w-auto">{{ $filters.currencyFormatter(product.price_e2 / 100) + '€ + IVA' }}</h3>
          </div>
        </div>
      </div>

      <div v-if="collaboration.is_premium">
        <h2 class="text-lg">{{ `Tú recibes: ${$filters.currencyFormatter(getCollabPrice)}€` }}</h2>
        <h2>(Base Imponible)</h2>
      </div>

      <div class="space-y-8 pt-2">
        <div v-if="loading" class="border rounded-lg w-full h-32 p-3 text-black animate-pulse bg-gray-300"/>
        <component
          v-else-if="collaboration.id"
          :is="component"
          :collaboration="collaboration"
          :loading="loading"
          :status="fixedStatus"
          @action-accept="fetch"
          @action-ignore="fetch"
          @action-book="fetch"
          @action-book-edit="fetch"
          @action-rate="fetch" />

        <cancel-collaboration-modal
          v-if="!isAvailable && !['ignored', 'pending', 'canceled', 'completed', 'expired'].includes(fixedStatus) && collaboration.id && !collaboration?.restaurant_rating"
          @cancel="fetch" />

        <div v-if="collaboration.foodies_meeting && (component !== 'ignored-action' && component !== 'expired-action')">
          <h1 class="text-default-xl text-left text-black">
            Foodies confirmados
          </h1>
          <ul class="divide-y border rounded-lg">
            <li v-if="foodies.length === 0 || foodies.filter((f) => ['accepted'].includes(f.pivot.status)).length === 0">
              <div class="space-x-2 flex p-1 items-center text-gray-400">
                <i class="icon-profile h-10 text-default-4xl" />
                <span>Aun no hay ningun foodie confirmado</span>
              </div>
            </li>
            <li v-for="foodie in foodies.filter((f) => ['accepted'].includes(f.pivot.status))" :key="foodie.id">
              <div class="space-x-2 flex p-1 items-center">
                <a-initials :name="foodie.name" :src="foodie.profile_picture" :desing-class="'w-10 h-10'"></a-initials>
                <div class="flex flex-col flex-grow items-start">
                  <p class="text-black custom-text-width" style="overflow: hidden; text-overflow: ellipsis;">
                    @{{foodie.ig_username.replace('@', '')}}
                  </p>
                  <small class="text-gray-400">
                    {{ foodie.ig_followers_count + ' followers'}}
                  </small>
                </div>

                <span class="text-sm pr-2" :class="{
                  'text-gray-400': foodie.pivot.status === 'pending',
                  'text-primary': foodie.pivot.status === 'accepted',
                  'text-SFRed': foodie.pivot.status === 'ignored' || foodie.pivot.status === 'expired'
                  }">
                  <span v-if="foodie.pivot.status === 'completed'">Completado</span>
                  <span v-if="foodie.pivot.status === 'accepted'">Confirmado</span>
                  <span v-if="foodie.pivot.status === 'pending'">Pendiente</span>
                  <span v-if="foodie.pivot.status === 'ignored'">Ignorado</span>
                  <span v-if="foodie.pivot.status === 'expired'">Ignorado</span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, provide, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useSession, useRepository } from '../../../../../hooks'

import CompletedAction from './components/actions/completed-action'
import AvailableAction from './components/actions/available-action'
import InvitationAction from './components/actions/invitation-action'
import PendingConfirmationAction from './components/actions/pending-confirmation-action'
import AcceptedAction from './components/actions/accepted-action'
import FallbackAction from './components/actions/fallback-action'
import CollaborationDescriptionCard from './components/collaboration-description-card'
import CollaborationContactCard from './components/collaboration-contact-card'
import CollaborationRequirementsCard from './components/collaboration-requirements-card'
import ActiveAction from './components/actions/active-action'
import ReservedAction from './components/actions/reserved-action'
import CanceledAction from './components/actions/canceled-action'
import ExpiredAction from './components/actions/expired-action'
import IgnoredAction from './components/actions/ignored-action'
import CancelCollaborationModal from './components/cancel-collaboration-modal.vue'
import Tag from '@/components/Tag.vue'

export default defineComponent({
  components: {
    CancelCollaborationModal,
    ReservedAction, // eslint-disable-line
    CollaborationContactCard, // eslint-disable-line
    CollaborationDescriptionCard, // eslint-disable-line
    CollaborationRequirementsCard,
    CompletedAction, // eslint-disable-line
    AvailableAction, // eslint-disable-line
    InvitationAction, // eslint-disable-line
    PendingConfirmationAction, // eslint-disable-line
    AcceptedAction, // eslint-disable-line
    FallbackAction, // eslint-disable-line
    ActiveAction, // eslint-disable-line
    IgnoredAction, // eslint-disable-line
    CanceledAction, // eslint-disable-lined
    ExpiredAction, // eslint-disable-lined
    Tag
  },
  setup () {
    const route = useRoute()
    const loading = ref(false)
    const collaboration = ref({})
    const { user } = useSession()
    const status = ref('')
    provide('collaboration', reactive(collaboration))

    const fetchRequests = useRepository(({ collaborations }) => ({
      foodieRelatedCollaboration: collaborations.foodie(user.foodie.id).show,
      collaboration: collaborations.show
    }))

    const getCollabPrice = computed(() => {
      let price = 0

      collaboration.value.products.map(product => {
        price += parseFloat(product.price_e2)
      })

      return (price / 100) * 0.9
    })

    const component = computed(() => {
      const predicate = (status.value ?? '-').split('-')[1]

      switch (predicate) {
        case 'canceled': return 'canceled-action'
        case 'ignored': return 'ignored-action'
        case 'open': return 'reserved-action'
        case 'expired': return 'expired-action'
        case 'active': return 'active-action'
        case 'pending':
          if (collaboration.value?.status === 'canceled') {
            return 'completed-action'
          } else {
            return 'invitation-action'
          }
        case 'pending_confirmation': return 'pending-confirmation-action'
        case 'accepted':
          if (collaboration.value.booking_id && collaboration.value.booking.status === 'canceled_by_provider') {
            return 'accepted-action'
          }
          if ((collaboration.value.booking_id && collaboration?.value?.booking?.status !== 'canceled') || (collaboration.value.foodies_meeting && collaboration?.value?.booking?.status !== 'canceled')) {
            return 'reserved-action'
          }
          return 'accepted-action'
        case 'completed':
          // console.log(collaboration.value.status)
          if (collaboration.value.status === 'closed' && !collaboration.value.restaurant_rated) {
            return 'reserved-action'
          }
          return 'reserved-action'

        default: return 'fallback-action'
      }
    })

    const fetch = () => {
      loading.value = !loading.value

      const requests = [
        fetchRequests.foodieRelatedCollaboration(route.params.id, { with: 'restaurant,collaboration.foodies,booking' }),
        fetchRequests.collaboration(route.params.id, { with: 'restaurant,foodies' })
      ]

      Promise.allSettled(requests)
        .then(([foodieRelatedResponse, collaborationResponse]) => {
          const shouldUseCollaboration = foodieRelatedResponse.status === 'rejected'
          collaboration.value = shouldUseCollaboration
            ? collaborationResponse.value.data
            : {
              ...foodieRelatedResponse.value.data.collaboration,
              booking_id: foodieRelatedResponse.value.data.booking_id,
              booking: foodieRelatedResponse.value.data.booking,
              restaurant: foodieRelatedResponse.value.data.restaurant,
              sololink: foodieRelatedResponse.value.data.sololink,
              restaurant_rating: foodieRelatedResponse.value.data.restaurant_rating,
              reservation_code: foodieRelatedResponse.value.data.reservation_code,
              publication_links: foodieRelatedResponse.value.data.publication_links,
              products: foodieRelatedResponse.value.data.products
            }
          collaboration.value.allowed_week_days = collaboration.value.allowed_week_days.map((day) => (parseInt(day)))
          status.value = shouldUseCollaboration
            ? `available-${collaborationResponse.value.data.status}`
            : `related-${foodieRelatedResponse.value.data.status}`
        })
        .finally(() => (loading.value = !loading.value))
    }

    const isOneDay = computed(() => collaboration.value.is_specific_day)

    onMounted(() => {
      fetch()
    })

    const isAvailable = computed(() => status.value.split('-')[0] === 'available')
    const fixedStatus = computed(() => status.value.split('-')[1])

    const foodies = computed(() => {
      let array = collaboration.value.foodies ? collaboration.value.foodies : []

      // if (collaboration.value.type === 'public') {
      array = array.filter(foodie => ['confirmed', 'accepted', 'ignored', 'expired'].includes(foodie.pivot.status))
      // array = array.filter(foodie => foodie.pivot.status === 'confirmed' || foodie.pivot.status === 'accepted')
      // }
      // return array
      return array.sort((a, b) => {
        const statuses = { pending: 0, confirmed: 1 }
        const aStatus = statuses[a.pivot.status]
        const bStatus = statuses[b.pivot.status]

        return aStatus === bStatus ? 0 : aStatus > bStatus ? 1 : -1
      })
    })

    return {
      isAvailable,
      isOneDay,
      status,
      fixedStatus,
      collaboration,
      loading,
      component,
      getCollabPrice,
      fetch,
      foodies
    }
  }
})
</script>

<style scoped>
/*.cityTag{*/
/*  position: absolute;*/
/*  background-color: #FEFCD7;*/
/*  padding: 4px 15px;*/
/*  border-radius: 8px;*/
/*  top: 0;*/
/*  margin-top: 5px;*/
/*  margin-left: 5px;*/
/*  color: #000;*/
/*}*/
@media (max-width: 400px) {
  .custom-text-width {
    max-width: 12rem !important;
  }
}

@media (max-width: 800px) {
  .custom-text-width {
    max-width: 60vw;
  }
}
</style>
