<template>
  <div class="flex flex-col text-black space-y-6 pt-4">
    <div v-if="collaboration.status !== 'closed' && status !== 'completed'">
      <div  v-if="!collaboration.foodies_meeting" class="flex flex-col">
        <div class="flex gap-2">
          <div v-if="collaboration?.booking?.zone !== null" class="flex-none text-left"><span class="font-bold">Zona: </span>{{ collaboration?.booking?.zone !== 'default' ? collaboration?.booking?.zone: 'Salón' }}</div>
          <div class="flex-auto">
            <h1 class="text-default-sm space-x-2" v-if="!collaboration?.foodies_meeting" :class="{ 'text-left': collaboration?.booking?.zone === null }">
              <b>Reserva: </b>{{ $filters.moment(collaboration?.booking?.date, 'DD/MM/YY') + ' - ' + collaboration?.booking?.time }}
            </h1>
          </div>
          <div class="flex-none text-right"><span class="font-bold">Personas: </span>{{ collaboration?.booking?.people_amount }}</div>
        </div>

        <div class="flex text-center mt-2">
          <button class="flex-1 text-primary ml-2" v-if="!hasPassedReservationDate" @click="toggleNotifyEdit">
            Editar
          </button>
          <div class="flex-1">
            <button class="text-red-400" v-if="!hasPassedReservationDate" @click="cancelReservationModal">
              Cancelar reserva
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="collaboration.restaurant.covermanager_id || collaboration.restaurant.settings.bookings_external_link" class="space-y-4">
      <p>Usa tu enlace personalizado para saber cuántas reservas llegan gracias a ti.</p>
      <div class="mx-auto block items-center">
<!--        <input type="text" id="sololink" class="text-gray-400 px-2 flex-grow text-left truncate mr-2" :value="code" disabled/>-->
        <button
          class="text-white rounded-full h-full px-4 py-2 w-44"
          :class="{'bg-red-500': copied === false,
          'bg-success': copied === true,
          'bg-onboarding_gray': copied === null}"
          :disabled="loadingCode"
          @click="generateSololink" >
          <template v-if="!loadingCode">
            <i class="icon-copy"/> {{ copied ? 'Enlace Copiado' : 'Copiar mi enlace' }}
          </template>
          <a-loader v-else color="text-white" class="mx-auto block w-5 h-5" />
        </button>
      </div>
    </div>

    <div class="mt-2">
      <h1 class="text-default-xl font-bold mb-2">
        ¿Qué tal fue la comida?
      </h1>
      <p class="text-sm">Copia y pega aquí los enlaces de tus publicaciones</p>
      <div class="space-y-1 mt-2">
        <div class="flex gap-2 items-center" v-for="(item, key) in urls" :key="key">
          <div class="border flex-grow rounded-full flex overflow-hidden items-center">
            <input
              type="text"
              class="focus:outline-none unstyled p-2 text-default-sm flex-grow"
              readonly
              :value="item"
              placeholder="Pega tu publicación">
            <i class="icon-checkmark text-primary mr-2" />
          </div>
          <button class="text-red-500 p-1" @click="removeLink(key);postShared()">
            <i class="icon-close" />
          </button>
        </div>

        <div class="border rounded-full mt-2 flex overflow-hidden items-center">
          <input
            type="text"
            class="focus:outline-none unstyled p-2 text-default-sm flex-grow"
            v-model="url"
            placeholder="Pega tu publicación">
          <!--          <i class="icon-checkmark text-primary mr-2" />-->
        </div>
        <div>
          <p v-if="invalidUrl" class="text-pointred">{{ invalidUrl }} El formato del link es incorrecto</p>
        </div>
      </div>
    </div>

    <div v-if="hasPassedReservationDate">
      <div class="space-y-2" v-if="!collaboration?.restaurant_rating">
        <rate-alert @rate="$emit('action-rate')" />
      </div>
      <div v-else>
      <span>
        Has valorado el <b>{{$filters.moment(collaboration?.foodie_pivot?.created_at, 'DD/MM/YYYY')}}</b>
      </span>
        <Rating :modelValue="collaboration?.restaurant_rating ? collaboration?.restaurant_rating?.rate : 0 " :stars="5" :cancel="false" :readonly="true" class="text-center w-full mt-5 mx-auto"/>
      </div>
    </div>

  </div>

  <a-alert :show="linkError" @confirm="linkError = false" hide-cancel>
    <h1 class="text-xl">
      Ha ocurrido un error al intentar crear el link.
    </h1>
    <h1>
      <b class="text-lg text-center mt-normal">Por favor intente nuevamente</b>
    </h1>
  </a-alert>

  <Booking
    v-model:show="isEditing"
    v-model:comment="comment"
    :available-dates="availableDatesList"
    :collaboration="collaboration"
    :restaurant="collaboration.restaurant"
    :loading-reservation="loadingBookingComponent"
    :reservation-form="{
        date: bookingFormValues.date,
        people_amount: bookingFormValues.people_amount,
        time: bookingFormValues.time,
        zone: bookingFormValues.zone,
        comment: comment
      }"
    @loading="loadingBookingComponent = $event"
    @validated-date="setReservationValues"
    @confirm-booking="editReserve"
  />
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido editar la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="$emit('action-book-edit');reserveSuccess = false"
    :show="reserveSuccess">
    <h2 class="">
      ¡Enhorabuena!
    </h2>
    <p class="text-lg">
      Se ha editado su reserva satisfactoriamente
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @cancel="cancelingReservation = false"
    @confirm="cancelReservation"
    :loading="loadingCancel"
    :title="'Cancela Reservación'"
    :show="cancelingReservation">
    <p class="text-lg text-center mt-4">
      ¿Desea cancelar la reserva?
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="$emit('action-book');canceled = false"
    :show="canceled">
    <p class="text-lg">
      Se ha cancelado su reserva
    </p>
  </a-alert>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch, inject } from 'vue'
import { useRepository, useSession, useArray } from '../../../../../../../hooks'
import { copyText } from 'vue3-clipboard'
import RateAlert from '../rate-alert.vue'
import Rating from 'primevue/rating'
import Moment from 'moment'
import Booking from '@/components/booking/booking-card'

export default defineComponent({
  name: 'reserved-action',
  components: { RateAlert, Rating, Booking },
  props: {
    collaboration: { required: true, type: Object },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      today: '',
      startAt: ''
    }
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  setup (props, { emit }) {
    const [urls, { remove: removeLink, append: appendLink }] = useArray([])
    const booking = useRepository(({ bookings }) => bookings)
    const $filters = inject('$filters', {})
    const url = ref('')
    const linkError = ref(false)
    const linkMessage = ref('ddd')
    const loadingCode = ref(false)
    const isEditing = ref(false)
    const date = ref('')
    const selectedHours = ref('empty')
    const selectedMinutes = ref('00')
    const peopleAmount = ref(1)
    const bookingFail = ref(false)
    const checkBookingFail = ref(false)
    const loadingBooking = ref(false)
    const bookingHours = ref([])
    const reserveSuccess = ref(false)
    const reserveError = ref(false)
    const reserveErrorMessage = ref('')
    const { user } = useSession()
    const repository = useRepository(({ foodies }) => foodies.sololinks(user.foodie.id))
    const collaborations = useRepository(({ collaborations }) => collaborations.foodie(user.foodie.id))
    const availableDatesList = ref([])
    const containerCopy = ref(null)
    const validDate = ref(false)
    const invalidUrl = ref(false)
    const cancelingReservation = ref(false)
    const loadingCancel = ref(false)
    const loadingLinks = ref(false)
    const linksSend = ref(null)
    const canceled = ref(false)
    const copied = ref(null)
    const providerZones = ref([])
    const zone = ref('')
    const zoneTime = ref('')
    const loadingBookingComponent = ref(false)
    const bookingFormValues = ref([])
    const bookingValues = ref(false)
    const invalidBookingDate = ref(false)
    const comment = ref('')
    const edited = ref(false)
    const urlsAux = ref([])

    const code = computed(() => {
      const sololinkCode = location.origin + '/' + user?.foodie?.ig_username + '/reserva/' + props.collaboration.restaurant.ig_username
      const externalLink = location.origin + '/' + user?.foodie?.ig_username + '/reserva/' + props.collaboration.restaurant.ig_username + '/external'
      // const externalLink = props.collaboration.restaurant?.settings.bookings_external_link
      return props.collaboration.restaurant.covermanager_id ? sololinkCode : externalLink
    })

    const lastUrlHasContent = computed(() => {
      const length = urls.length - 1
      const last = urls[length]

      return last.url !== ''
    })
    const hasPassedReservationDate = computed(() => {
      if (props.collaboration.foodies_meeting) {
        const reservationDate = Moment(props?.collaboration?.start_at).utc()
        const today = Moment(new Date()).utc()
        return today.isAfter(reservationDate)
      } else {
        if (props?.collaboration?.booking?.date) {
          const reservationDate = Moment(props?.collaboration?.booking?.date + ' ' + props?.collaboration?.booking?.time).utc()
          const today = Moment(new Date()).utc()
          return today.isAfter(reservationDate)
        } else {
          return true
        }
      }
    })

    const copyToClipBoard = () => {
      const content = document.getElementById('sololink')
      content.select()
      content.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }

    onMounted(() => {
      // generateSololink()
      edited.value = false
      isEditing.value = false
      // code.value = props.collaboration.sololink?.uuid ?? ''
      peopleAmount.value = props.collaboration?.booking?.people_amount - 1
      if (props.collaboration.publication_links) {
        const links = props.collaboration.publication_links
        links.forEach(function (url) {
          appendLink(url)
        })
      }
      loadingBooking.value = true
      date.value = props?.collaboration?.booking?.date
      comment.value = props?.collaboration?.booking?.comment
      if (!props?.collaboration?.restaurant?.covermanager_id) {
        loadingBooking.value = false
      }

      if (props?.collaboration?.restaurant?.covermanager_id) {
        if (selectedHours.value === 'empty') {
          selectedHours.value = selectedHours.value === 'empty' ? $filters.moment(new Date(), 'hh:mm') : '00:00'
        }
      } else {
        selectedHours.value = props?.collaboration?.booking?.time
      }
    })

    const setReservationValues = (value) => {
      if (value) {
        bookingFormValues.value = (value)
        bookingValues.value = true
      } else {
        bookingValues.value = false
      }
    }

    // const availableDates = () => {
    //   booking.availableDates({ restaurant_id: props?.collaboration?.restaurant?.id, people_count: props?.collaboration?.booking?.people_amount })
    //     .then(({ data: response }) => {
    //       availableDatesList.value = response?.available_dates
    //     })
    //     .finally(() => (loadingBooking.value = false))
    // }

    const hasRated = () => {
      // if (props?.collaboration.restaurant_rating) {
      //   return true
      // } else {
      //   return false
      // }
      return true
    }

    const generateSololink = () => {
      if (copied.value === null) {
        loadingCode.value = !loadingCode.value
        repository.store({ restaurant_id: props.collaboration.restaurant.id })
          .finally(() => (loadingCode.value = !loadingCode.value))
      }
      doCopy()
      // code.value = location.origin + '/' + user?.foodie?.ig_username + '/reserva/' + props.collaboration.restaurant.ig_username
      // repository.getSololinks(props.collaboration.restaurant.id)
      //   .then((response) => {
      //     if (response?.data?.length > 0) {
      //       code.value = location.origin + '/' + user?.foodie?.ig_username + '/reserva/' + response.data[0].uuid
      //     } else {
      // repository.store({ restaurant_id: props.collaboration.restaurant.id })
      // .then(({ data }) => {
      //   code.value = location.origin + '/' + user?.foodie?.ig_username + '/reserva/' + data?.uuid
      // })
      // .catch(({ response }) => {
      //   if (response?.data?.error_code) {
      //     linkError.value = true
      //   }
      // })
      // .finally(() => (loadingCode.value = !loadingCode.value))
      //   }
      // })
      // .catch(({ response }) => {
      //   if (response?.data?.error_code) {
      //     linkError.value = true
      //   }
      // })
      // .finally(() => (loadingCode.value = !loadingCode.value))
    }
    const doCopy = () => {
      copyText(code.value.toString(), undefined, (error, event) => {
        if (error) {
          console.log('No se pudo copiar', error)
          copied.value = false
        } else {
          console.log('copied!')
          copied.value = true
        }
      })
    }

    const checkReserve = (type) => {
      switch (type) {
        case 'date': {
          break
        }
        // case 'people': {
        //   selectedHours.value = '00:00'
        //   break
        // }
        case 'hours': {
          break
        }
      }

      loadingBooking.value = true
      bookingFail.value = false

      peopleAmount.value = parseInt(peopleAmount.value)

      const body = {
        restaurant_id: props.collaboration.restaurant.id,
        date: date.value + ' ' + selectedHours.value,
        people_amount: peopleAmount.value
      }
      if (zone.value) {
        body.cm_zone = zone.value
      }

      booking.verifyAvailability(body)
        .then(({ data }) => {
          // people.value = Object.keys(data.provider_response.availability.people)
          // people.value = people.value.map((value) => value <= props.collaboration.companion_limit)
          bookingHours.value = data.available_hours
          bookingFail.value = !data.available
          checkBookingFail.value = false
        })
        .catch(({ response }) => {
          checkBookingFail.value = true
          reserveErrorMessage.value = ''
          switch (response?.data?.error_code) {
            case 'BK001': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK002': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK003': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK004': {
              reserveErrorMessage.value = 'Hora no disponible'
              break
            }
            case 'BK005': {
              reserveErrorMessage.value = 'No se pudo crear la reserva'
              break
            }
            case 'BK006': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK007': {
              reserveErrorMessage.value = 'Fecha no disponible, ya existe una reserva con este restaurante'
              break
            }
            case 'BK008': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK009': {
              reserveErrorMessage.value = 'Acción no disponible'
              break
            }
            case 'BK010': {
              reserveErrorMessage.value = 'Proveedor no disponible'
              break
            }
            default: {
              reserveErrorMessage.value = ''
              break
            }
          }
        })
        .finally(() => (loadingBooking.value = false))
    }

    const editReserve = () => {
      loadingBookingComponent.value = true
      let form = {}
      if (props?.collaboration?.restaurant?.covermanager_id) {
        form = {
          date: bookingFormValues.value.date,
          people_amount: bookingFormValues.value.people_amount,
          zone: bookingFormValues.value.zone,
          comment: comment.value
        }
      } else {
        form = {
          date: bookingFormValues.value.date,
          people_amount: bookingFormValues.value.people_amount,
          use_booking_provider: false,
          comment: comment.value
        }
      }

      booking.update(props.collaboration.booking.id, form)
        .then(() => {
          reserveSuccess.value = true
        })
        .catch(() => {
          reserveError.value = true
          date.value = ''
        })
        .finally(() => {
          loadingBookingComponent.value = false
          isEditing.value = false
          edited.value = true
        })
    }
    const postShared = () => {
      loadingLinks.value = true
      collaborations.postShared(props.collaboration.id, { publication_links: urls.value })
        .then(({ data: response }) => {
          linksSend.value = true
          urlsAux.value = response.publication_links
        })
        .catch(() => (linksSend.value = false))
        .finally(() => (loadingLinks.value = false))
    }

    watch(url, () => {
      linksSend.value = null
      try {
        const validUrl = new URL(url.value)
        if (validUrl) {
          appendLink(url.value)
          postShared()
          url.value = ''
          invalidUrl.value = false
        } else {
          invalidUrl.value = true
        }
      } catch (_) {
        return false
      }
    })

    const cancelReservation = () => {
      loadingCancel.value = true
      booking.cancel(props.collaboration.booking.id)
        .then(() => {
          cancelingReservation.value = false
          canceled.value = true
          // this.$emit('action-book')
        })
        .finally(() => (loadingCancel.value = false))
        .catch((err) => (console.log(err)))
    }

    const toggleNotifyEdit = () => {
      isEditing.value = !isEditing.value
    }

    const cancelReservationModal = () => {
      cancelingReservation.value = !cancelingReservation.value
    }

    const changedZone = (value) => {
      zone.value = value
    }

    return {
      urls,
      linkError,
      lastUrlHasContent,
      generateSololink,
      code,
      loadingCode,
      hasPassedReservationDate,
      url,
      removeLink,
      toggleNotifyEdit,
      isEditing,
      linkMessage,
      editReserve,
      checkReserve,
      date,
      selectedHours,
      selectedMinutes,
      bookingHours,
      bookingFail,
      loadingBooking,
      reserveSuccess,
      reserveError,
      reserveErrorMessage,
      checkBookingFail,
      peopleAmount,
      hasRated,
      postShared,
      availableDatesList,
      copyToClipBoard,
      containerCopy,
      validDate,
      invalidUrl,
      cancelReservation,
      cancelReservationModal,
      cancelingReservation,
      loadingCancel,
      canceled,
      doCopy,
      copied,
      providerZones,
      zone,
      zoneTime,
      loadingBookingComponent,
      bookingFormValues,
      setReservationValues,
      changedZone,
      invalidBookingDate,
      comment,
      edited,
      loadingLinks,
      linksSend,
      urlsAux
    }
  },
  mounted () {
    this.today = Moment(new Date().toISOString())
    this.startAt = Moment(this.collaboration.start_at)
  }
})
</script>
<style scoped>
input[id^='zoneValue']:checked + label{
  @apply bg-primary text-white border-none
}
</style>
