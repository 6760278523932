<template>
  <div class="space-y-2">

    <h1 class="text-default-lg text-black">
      ¿Quieres colaborar con nosotros?
    </h1>

    <button
      :disabled="loading || accepting || ignoring"
      @click="collaboration.is_premium ? modals.premium = true : accept()"
      :class="collaboration.is_premium ? 'bg-yellow_ribbon text-onboarding_gray' : 'bg-primary text-white'"
      class="bg-primary focus:outline-none flex justify-center font-normal w-full p-3 mx-auto rounded-full disabled:bg-gray md:w-2/5">
      <a-loader v-if="loading || accepting" color="text-white" class="h-6 w-6" />
      <template v-else>
        <span v-if="collaboration.is_premium"><b>Aceptar colaboración premium</b></span>
        <span v-else><b>{{ collaboration.foodies_meeting && !(collaboration.type === 'private') ? 'Solicitar colaboración' : 'Aceptar invitación' }}</b></span>
      </template>
    </button>

    <button
      :disabled="loading || ignoring || accepting"
      @click="ignore"
      class="bg-white border border-onboarding_gray focus:outline-none flex justify-center text-onboarding_gray w-full p-3 mx-auto rounded-full md:w-2/5 disabled:bg-gray">
      <a-loader v-if="loading || ignoring" color="text-white" class="h-6 w-6" />
      <template v-else>
        Rechazar
      </template>
    </button>
  </div>

  <a-alert hide-cancel :show="modals.ignored" @confirm="redirectIgnore">
    <div class="space-y-3">
      <h1 class="text-lg">
        Has rechazado esta colaboración.
      </h1>
    </div>
  </a-alert>

  <a-alert
    title="Colaboración aceptada"
    :show="modals.accepted"
    hide-cancel
    disabled-overlay-dismiss
    @confirm="acceptRedirect">
    <div class="space-y-3">
      <h1 class="text-lg text-gray-800">
        ¡Enhorabuena!
      </h1>
      <template v-if="!collaboration?.restaurant?.covermanager_id">
        <h1 class="text-lg">
          {{ user.foodie.ig_username ? '@' + user.foodie.ig_username : user.foodie.name }} debes indicar en tu reserva el código <b>{{ response.reservation_code }}</b> para garantizar los beneficios de colaboración
        </h1>
        <h1 class="text-lg">
          Llama al número <b class="text-primary"><a :href="`tel:${collaboration?.restaurant?.phone}`"></a>{{ collaboration?.restaurant?.phone }}</b> del restaurante para hacer tu reserva.
        </h1>
      </template>
      <template v-else>
        <h1 class="text-lg">
          {{ user.foodie.ig_username ? '@' + user.foodie.ig_username : user.foodie.name }} tu código de reserva es <b>{{ response.reservation_code }}</b>
        </h1>
      </template>
    </div>
  </a-alert>

  <a-alert
      title="Percibirás un pago por esta colaboración"
      :show="modals.premium">
<!--    <h3 class="text-sm -mt-2 text-onboarding_ligth_gray text-center">Acepta para continuar</h3>-->
    <div class="flex flex-col">
      <div class="flex items-start mt-2">
        <input type="checkbox" value="1" id="1" class="mt-1" v-model="terms.a">
        <label for="1" class="ml-2">Entiendo y acepto que demoras o entregas parciales resultan en reembolso total al cliente.</label>
      </div>
      <div class="flex items-start mt-2">
        <input type="checkbox" value="2" id="2" class="mt-1" v-model="terms.b">
        <label for="2" class="ml-2">He leído, entiendo y acepto los requerimientos exigidos en esta colaboración.</label>
      </div>
      <div class="flex items-start mt-2">
        <input type="checkbox" value="3" id="3" class="mt-1" v-model="terms.c">
        <label for="3" class="ml-2">He leído, entiendo y acepto los <a href="/terms" target="_blank" class="underline text-primary">términos y condiciones</a>.</label>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-between m-2">
        <button
            @click="modals.premium = false"
            class="bg-onboarding_light_gray py-2 text-white w-full rounded-full overflow-hidden mx-1">
          <span>Cancelar</span>
        </button>
        <button
            :class="!terms.a || !terms.b || !terms.c ? 'opacity-50' : ''"
            class="bg-primary py-2 text-white w-full rounded-full overflow-hidden mx-1"
            @click="accept"
            :disabled="!terms.a || !terms.b || !terms.c || accepting ">
          <a-loader v-if="accepting" color="text-white" class="h-6 w-6 block mx-auto" />
          <span v-else>Confirmar</span>
        </button>
      </div>
    </template>
  </a-alert>

  <a-alert
    title="Confirmación pendiente"
    hide-cancel
    :show="failed"
    @confirm="failed = false">
    <h1 class="text-lg">
<!--      Has llegado a tu máximo de colaboraciones activas. <br>Publica y valora antes de aceptar nuevas colaboraciones-->
      {{ errorMessage }}
    </h1>
  </a-alert>
</template>

<script>
import { mapState } from 'vuex'
import { useErrors } from '@/hooks'

export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  data: () => ({
    modals: {
      accepted: false,
      ignored: false,
      premium: false
    },
    accepting: false,
    ignoring: false,
    failed: false,
    response: {},
    errorMessage: '',
    terms: {
      a: false,
      b: false,
      c: false
    }
  }),
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    acceptRedirect () {
      this.$emit('action-accept')
    },
    redirectIgnore () {
      // this.$emit('action-ignore')
      this.$router.replace({ path: '/collaborations' })
    },
    accept () {
      this.modals.premium = false
      this.accepting = !this.accepting
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .accept(this.collaboration.id)
        .then(({ data }) => {
          this.response = {
            reservation_code: data.reservation_code
          }
          if (!this.collaboration.restaurant.covermanager_id) {
            this.modals.accepted = true
          } else {
            this.acceptRedirect()
          }
        })
        .catch((err) => {
          this.failed = true
          this.errorMessage = useErrors(err?.response?.data?.error_code).message
        })
        .finally(() => (this.accepting = !this.accepting))
    },
    ignore () {
      this.ignoring = !this.ignoring
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .ignore(this.collaboration.id)
        .then(() => {
          this.modals.ignored = true
        })
        .finally(() => (this.ignoring = !this.ignoring))
    }
  }
}
</script>
<style scoped>
input[type="checkbox"] {
  min-width: 13px !important;
  max-width: 13px !important;
}
</style>
