<template>
  <div class="space-y-2">

    <p class="text-gray-400 text-center text-default-lg">
      Colaboración ignorada
    </p>

<!--    <button-->
<!--      v-if="collaboration?.restaurant?.covermanager_id"-->
<!--      class="bg-primary focus:outline-none flex justify-center text-white w-full p-3 rounded-full disabled:bg-gray" @click="showReserveModal = true">-->
<!--      <a-loader v-if="storing" color="text-white" class="h-6 w-6" />-->
<!--      <template v-else>-->
<!--        Reservar una mesa-->
<!--      </template>-->
<!--    </button>-->

<!--    <button v-else class="bg-white border border-primary focus:outline-none flex justify-center text-primary mb-small w-full p-3 rounded-full disabled:bg-gray" @click="showReserveModal = true">-->
<!--      <a-loader v-if="storing" color="text-white" class="h-6 w-6" />-->
<!--      <template v-else>-->
<!--        Ya tengo reserva-->
<!--      </template>-->
<!--    </button>-->
  </div>

  <a-alert
    @confirm="reserve"
    :loading="loadingBooking"
    :show="showReserveModal"
    :disabled-confirm="!date || bookingFail || checkBookingFail"
    @cancel="showReserveModal = false">
    <h1 class="text-default-lg text-black">
      Reservar una mesa
    </h1>

    <div class="space-y-4 text-black mt-2">
      <section>
        <span>Para el día</span>
<!--        <div v-if="collaboration?.restaurant?.covermanager_id" class="flex flex-col items-center">-->
<!--          <input-->
<!--            name="reservationInput"-->
<!--            id="reservationInput"-->
<!--            class="px-2 py-1.5 w-full bg-white border rounded-lg"-->
<!--            type="date"-->
<!--            @change="checkReserve('date')"-->
<!--            :min="startAt.isAfter(today) ? $filters.moment(collaboration.start_at, 'YYYY-MM-DD') : $filters.moment(new Date(), 'YYYY-MM-DD')"-->
<!--            :max="$filters.moment(collaboration.end_at, 'YYYY-MM-DD')"-->
<!--            v-model="date" />-->
<!--          <div v-show="date" class="flex pt-2 w-full">-->
<!--            <div class="flex-1 text-center">-->
<!--              <p>Acompañantes</p>-->
<!--              <select v-model="peopleAmount" name="people" id="people" @change="checkReserve('people')">-->
<!--                <option>0</option>-->
<!--                <option v-for="person in collaboration.companions_limit" :key="person" :active="person[0]">{{ person }}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="flex-1 text-center">-->
<!--              <div v-if="bookingHours.length > 0">-->
<!--                <p>Hora</p>-->
<!--                <select v-model="selectedHours" name="hours" id="hours" @change="checkReserve('hours')">-->
<!--                  <option v-for="hour in bookingHours" :key="hour" :active="hour[0]">{{ hour }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        <div v-if="collaboration?.restaurant?.covermanager_id">
          <Booking
            :collaboration="collaboration"
            :restaurant="collaboration?.restaurant"
            :availableDates="dateTest"
            @loading="loadingBookingComponent = $event"
            @validated-date="setReservationValues"
            @invalid-date="invalidBookingDate = $event"
            @unavailable-range-date="validDate = false"
          />
        </div>

        <div v-else class="flex flex-col items-center">
          <input
            name="reservationInput2"
            id="reservationInput2"
            class="px-2 py-1.5 w-full bg-white border rounded-lg"
            type="date"
            :min="startAt.isAfter(today) ? $filters.moment(collaboration.start_at, 'YYYY-MM-DD') : $filters.moment(new Date(), 'YYYY-MM-DD')"
            :max="$filters.moment(collaboration.end_at, 'YYYY-MM-DD')"
            v-model="date" />
          <div class="flex pt-2 w-full">
            <div class="flex-1 text-center">
              <select v-model="selectedHours" name="hours" id="hours2" class="pl-2" size="1">
                <option v-for="hour in hours" :key="hour" :active="hour[0]">{{ hour }}</option>
              </select>
            </div>
            <div class="flex-none text-center">
              <span class="text-lg font-bold align-middle px-1">:</span>
            </div>
            <div class="flex-1 text-center">
              <select v-model="selectedMinutes" name="minutes" id="minutes">
                <option>00</option>
                <option>15</option>
                <option>30</option>
                <option>45</option>
              </select>
            </div>

          </div>
        </div>
      </section>

      <div v-if="bookingFail && !bookingHours.length">
        <p class="text-red">No hay disponibilidad para la realizar la reserva</p>
      </div>
      <div v-if="checkBookingFail">
        <p class="text-red">{{ reserveErrorMessage }}</p>
      </div>

      <section>
        <span>¿Algún comentario?</span>
        <textarea
          v-model="comment"
          rows="4"
          placeholder="Introduce cualquier petición adicional (ten en cuenta que no están garantizadas)"
          class="border rounded-xl w-full p-2 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"></textarea>
      </section>
    </div>
    <template #confirm>
      <div v-if="loadingBooking" style="min-width: 45px;">
        <a-loader class="h-6 w-6 mx-auto" :color="'text-dgray'"></a-loader>
      </div>
      <div v-else>
        <p>Aceptar</p>
      </div>
    </template>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido crear la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveSuccess = false"
    :show="reserveSuccess">
    <h2 class="">
      ¡Enhorabuena!
    </h2>
    <p class="text-lg">
      Se ha creado su reserva satisfactoriamente
    </p>
  </a-alert>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useSession, useRepository } from '@/hooks'
import Moment from 'moment'
import Booking from '@/components/booking/booking'
// import PrimeCalendar from 'primevue/calendar'
export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  components: {
    Booking
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  setup (props, { emit }) {
    const { user } = useSession()
    const showReserveModal = ref(false)
    const repository = useRepository(({ bookings }) => bookings.foodies(user.foodie.id))
    const storing = ref(false)
    const $filters = inject('$filters', {})
    const bookingProvider = ref(false)
    const loadingBooking = ref(false)
    const date = ref('')
    const comment = ref('')
    const selectedHours = ref('empty')
    const selectedMinutes = ref('00')
    const reserveError = ref(false)
    const reserveErrorMessage = ref('')
    const reserveSuccess = ref(false)
    const bookingFail = ref(false)
    const checkBookingFail = ref(false)
    const peopleAmount = ref(1)
    const booking = useRepository(({ bookings }) => bookings)
    const people = ref(0)
    const bookingHours = ref([])

    const reserve = () => {
      loadingBooking.value = true
      storing.value = !storing.value
      // date.value = date.value + 'T' + selectedHours.value + ':' + selectedMinutes.value
      // date.value = $filters.moment(date.value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      // let fullDate = date.value + 'T' + selectedHours.value + ':' + selectedMinutes.value
      let fullDate = props.collaboration?.restaurant?.covermanager_id !== null
        ? date.value + 'T' + selectedHours.value
        : date.value + 'T' + selectedHours.value + ':' + selectedMinutes.value
      fullDate = $filters.moment(fullDate, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
      bookingProvider.value = props.collaboration?.restaurant?.covermanager_id !== null
      const form = { date: fullDate, comment: comment.value, use_booking_provider: bookingProvider.value }
      repository.store({ ...form, collaboration_id: props.collaboration.id })
        .then(() => {
          emit('action-book')
          showReserveModal.value = false
          reserveSuccess.value = true
        })
        .catch(({ response }) => {
          reserveError.value = true
          showReserveModal.value = false
          date.value = ''
          switch (response?.data?.error_code) {
            case 'BK001': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK002': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK003': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK004': {
              reserveErrorMessage.value = 'Hora no disponible'
              break
            }
            case 'BK005': {
              reserveErrorMessage.value = 'No se pudo crear la reserva'
              break
            }
            case 'BK006': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK007': {
              reserveErrorMessage.value = 'Ya tienes unas reserva con este restaurante'
              break
            }
            case 'BK008': {
              reserveErrorMessage.value = ''
              break
            }
            default: {
              reserveErrorMessage.value = ''
              break
            }
          }
        })
        .finally(() => {
          storing.value = !storing.value
          loadingBooking.value = false
        })
    }

    const checkReserve = (type) => {
      switch (type) {
        case 'date': {
          break
        }
        case 'people': {
          selectedHours.value = '00:00'
          break
        }
        case 'hours': {
          break
        }
      }
      loadingBooking.value = true
      bookingFail.value = false
      peopleAmount.value = parseInt(peopleAmount.value)

      let peopleAmountFix = peopleAmount.value

      peopleAmountFix = peopleAmountFix + 1

      booking.verifyAvailability({ restaurant_id: props.collaboration.restaurant.id, date: date.value + ' ' + selectedHours.value, people_amount: peopleAmountFix })
        .then(({ data }) => {
          people.value = Object.keys(data.provider_response.availability.people)
          // people.value = people.value.map((value) => value <= props.collaboration.companion_limit)
          bookingHours.value = data.available_hours
          bookingFail.value = !data.available
          loadingBooking.value = false
          checkBookingFail.value = false
        })
        .catch(({ response }) => {
          checkBookingFail.value = true
          reserveErrorMessage.value = ''
          switch (response?.data?.error_code) {
            case 'BK001': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK002': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK003': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK004': {
              reserveErrorMessage.value = 'Hora no disponible'
              break
            }
            case 'BK005': {
              reserveErrorMessage.value = 'No se pudo crear la reserva'
              break
            }
            case 'BK006': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK007': {
              reserveErrorMessage.value = 'Fecha no disponible, ya existe una reserva con este restaurante'
              break
            }
            case 'BK008': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK009': {
              reserveErrorMessage.value = 'Acción no disponible'
              break
            }
            case 'BK010': {
              reserveErrorMessage.value = 'Proveedor no disponible'
              break
            }
            default: {
              reserveErrorMessage.value = ''
              break
            }
          }
        })
        .finally(() => (loadingBooking.value = false))
    }

    const testing = () => {
      const newDate = date.value + 'T' + selectedHours.value + ':' + selectedMinutes.value

      console.log(new Date(newDate))
    }

    onMounted(() => {
      // selectedHours.value = props?.collaboration?.restaurant?.covermanager_id ? '00:00' : '00'
      if (props?.collaboration?.restaurant?.covermanager_id) {
        if (selectedHours.value === 'empty') {
          selectedHours.value = selectedHours.value === 'empty' ? $filters.moment(new Date(), 'hh:mm') : '00:00'
        }
      } else {
        selectedHours.value = '00'
      }
    })

    return {
      date,
      comment,
      storing,
      reserve,
      testing,
      checkReserve,
      showReserveModal,
      selectedHours,
      selectedMinutes,
      bookingProvider,
      reserveError,
      reserveErrorMessage,
      reserveSuccess,
      peopleAmount,
      loadingBooking,
      bookingHours,
      bookingFail,
      checkBookingFail
    }
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      today: '',
      startAt: ''
    }
  },
  methods: {
    showModal () {
      this.showReserveModal = true
      // const today = this.$filters.moment(new Date(), 'YYYY-MM-DDT00:00')
      // const collabMaxDay = this.$filters.moment(this.collaboration.end_at, 'YYYY-MM-DDT00:00')
      // document.getElementById('reservationInput').setAttribute('min', today)
      // document.getElementById('reservationInput').setAttribute('max', collabMaxDay)
    }
  },
  mounted () {
    this.today = Moment(new Date().toISOString())
    this.startAt = Moment(this.collaboration.start_at)
  }
  // mounted () {
  //   console.log(this.collaboration.restaurant)
  //   this.bookingProvider = this.collaboration?.restaurant?.covermanager_id === null
  //   console.log(this.bookingProvider)
  // }
  // mounted () {
  //   // document.getElementById('reservationInput').step = 900
  //   // document.getElementById('input').setAttribute('max', this.collaboration.end_at)
  // }
  // data: () => ({
  //   loadings: {
  //     booking: false
  //   },
  //   form: {
  //     date: '',
  //     comment: ''
  //   }
  // }),
  // computed: mapState({
  //   user: ({ session }) => session.user
  // }),
  // methods: {
  //   book () {
  //     this.loadings.booking = !this.loadings.booking
  //     this.$repository.bookings
  //       .foodies(this.user.foodie.id)
  //       .store({ ...this.form, collaboration_id: this.collaboration.id })
  //       .finally(() => (this.loadings.booking = !this.loadings.booking))
  //   }
  // }
}
</script>
