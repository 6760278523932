<template>
  <div class="flex-grow flex fixed lg:bottom-2 bottom-14 w-full max-w-5xl mx-auto items-end justify-center py-3 px-4">
    <button
      v-if="status === 'ignored'"
      disabled
      class="bg-primary focus:outline-none flex justify-center text-white w-full p-3 rounded-full uppercase disabled:bg-gray">
      <a-loader v-if="loading" color="text-white" class="h-6 w-6" />
      <template v-else>
        {{ 'Colaboración ignorada' }}
      </template>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  }
}
</script>
