<template>
  <button class="bg-success px-4 space-x-2 inline-flex items-center justify-center py-2 rounded-full text-white" @click="show = true">
    <i class="icon-thumbsUp font-bold" />
    <span>Valorar la colaboración</span>
  </button>

  <a-alert
    :show="show"
    @cancel="show = false"
    @confirm="rate"
    confirm-text="Enviar"
    cancel-text="Cancelar"
    :disabled-confirm="isDisabledConfirmRating.value"
    title="Valorar Restaurante">
    <h1 class="text-lg mb-1 text-center">
      ¿Has compartido en tus redes?
    </h1>
    <div class="flex justify-center space-x-4 mb-4">
      <button @click="form.post_shared = true" class="h-12 w-12 flex items-center justify-center rounded-full" :class="{ 'bg-primary text-white': form.post_shared }">
        SI
      </button>
      <button @click="form.post_shared = false" class="h-12 w-12 flex items-center justify-center rounded-full" :class="{ 'bg-primary text-white': form.post_shared === false }">
        NO
      </button>
    </div>
    <Rating v-model="form.rate" :stars="5" :cancel="false" class="text-center w-full mt-5 mx-auto"/>
    <div v-if="isDisabledConfirmRating.value || form.comment.length >= 199" class="h-5 right text-right w-100 text-sm text-onboarding_light_gray">{{ form.comment.length + '/' + '200'}}</div>
    <div v-else class="h-5"/>
    <textarea v-model="form.comment" :placeholder="`${isDisabledConfirmRating.message ? 'Cuéntanos ¿Por qué has decidido no publicar? (Requerido)' : 'Comentario (Opcional)'}`" class="focus:outline-none w-full p-2 border rounded-md" rows="5"></textarea>
    <div class="text-center mx-auto mt-2">
      <div class="space-x-2 text-sm">
        <p>
          <!--            Aceptar los <router-link class="text-primary">términos y condiciones</router-link>-->
          Al hacer una reseña aceptas que SoloFoodies trate tus datos según nuestra Política de Privacidad. Los recogemos para facilitar la publicación, gestión y control de las opiniones de los usuarios. No compartiremos tus datos con terceros, salvo obligación legal. Puedes ejercer tus derechos de acceso, rectificación, supresión y oposición, entre otros, según nuestra Política de Privacidad.
        </p>
      </div>
    </div>

    <template #confirm v-if="loading">
      <a-loader color="text-primary" class="w-5 h-5" />
    </template>
    <!--    <span class="test-gray-300 inline-block text-sm" v-text="isDisabledConfirmRating.message" />-->
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="$emit('rate')"
    :show="successRate">
    <h2 class="">
      ¡Gracias!
    </h2>
    <p class="text-lg">
      Tu valoración ayuda a todos los que formamos parte de esta comunidad
    </p>
  </a-alert>
</template>

<script>
import { defineComponent, ref, reactive, computed, inject } from 'vue'
import Rating from 'primevue/rating'
import { useRepository, useSession } from '../../../../../../hooks'
export default defineComponent({
  components: { Rating },
  emits: ['rate'],
  setup (_, { emit }) {
    const show = ref(false)
    const loading = ref(false)
    const successRate = ref(false)
    const form = reactive({
      post_shared: false,
      rate: 5,
      comment: ''
    })

    const collaboration = inject('collaboration', {})
    const request = useRepository(({ collaborations }) => collaborations.collaboration(collaboration.value.id))
    const { user } = useSession()

    const isDisabledConfirmRating = computed(() => {
      return {
        value: !(form.rate >= 0) || (!form.post_shared && !(form.comment.length >= 200)) || loading.value,
        message: (form.post_shared === false && !(form.comment.length >= 200))
          ? 'Si no has compartido la publicación, por favor envianos un comentario'
          : null
      }
    })

    const rate = () => {
      loading.value = !loading.value
      request.rateRestaurant(user.foodie.id, {
        ...form,
        rate: form.rate === 0
          ? 1
          : form.rate
      })
        .then(() => {
          // emit('rate')
          successRate.value = true
        })
        .finally(() => {
          loading.value = !loading.value
          show.value = false
        })
    }

    return {
      show,
      form,
      isDisabledConfirmRating,
      rate,
      loading,
      successRate
    }
  }
})
</script>
