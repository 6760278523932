<template>
  <div class="my-4 w-full">
    <div class="flex flex-col gap-2 content-center border-b border-gray pb-2 sm:flex">
      <div class="flex gap-3">
        <div class="flex-none gap-2 w-16 text-left">
          <div class="flex flex-col gap-2">
            <label for="companionSelectorSS">Personas</label>
            <select id="companionSelectorSS" class="text-lg border p-1 rounded py-4 px-2 font-bold" v-model="peopleAmount" @change="checkReserve(false)">
              <option v-for="person in collaboration.companions_limit + 1" :key="person" :active="person[collaboration.companions_limit.length + 1]">{{ person }}</option>
            </select>
          </div>
        </div>
        <div class="flex-auto gap-2 text-left">
          <div class="flex flex-col gap-2">
            <label for="dateSelector">Fecha</label>
            <select :disabled="loading" id="dateSelector" v-model="date" name="dateTest" class="border p-1 rounded py-4 px-2 font-bold disabled:bg-gray" @change="checkReserve(false)">
              <option selected disabled class="text-gray" value="">Elegir fecha</option>
              <option v-for="date in filteredDates" :key="date" :value="date.value">{{ date.label.replaceAll('.', '') }}</option>
            </select>
          </div>
        </div>

        <div class="flex-none text-left mb-4 w-24">
          <div class="flex flex-col gap-2">
            <label for="hourSelector">Hora</label>
            <select
              :disabled="loading || !date || failProviderZones"
              id="hourSelector" v-model="selectedHour"
              class="text-lg border p-1 rounded disabled:bg-gray py-4 px-2 font-bold"
              @change="checkReserve(false)">
              <option v-for="hour in hoursList" :key="hour">{{ hour }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useRepository } from '@/hooks'
import Moment from 'moment'
// import ZoneCard from './zone-card'
// import Moment from 'moment'
export default {
  name: 'booking-uncover',
  // components: { ZoneCard },
  props: {
    collaboration: { required: false, type: Object },
    restaurant: { required: false, type: Object },
    availableDates: { required: false, type: Object },
    // providerZones: { required: false, type: Object },
    soloLink: { required: false, type: Boolean, default: false }
  },
  setup (props, { emit }) {
    const booking = useRepository(({ bookings }) => bookings)
    // const $filters = inject('$filters', {})
    const date = ref('empty')
    const peopleAmount = ref(1)
    const zone = ref(null)
    const selectedHour = ref(null)
    const bookingHours = ref([])
    const providerZones = ref(null)
    const loading = ref(false)
    const loadingCheck = ref(false)
    const bookingFail = ref(false)
    const bookingFailError = ref(false)
    const matches = ref([])
    const failProviderZones = ref(false)
    const confirmModal = ref(false)
    const availableDatesList = ref([])
    const validDate = ref(false)
    const haveAvailableDates = ref(true)

    const filteredDates = computed(() => {
      function getDatesInRange (startDate, endDate) {
        // const date = new Date(startDate.getTime())
        //
        // // ✅ Exclude start date
        // date.setDate(date.getDate() + 1)
        //
        // const dates = []
        //
        // // ✅ Exclude end date
        // // eslint-disable-next-line no-unmodified-loop-condition
        // while (date < endDate) {
        //   dates.push(new Date(date).toISOString())
        //   date.setDate(date.getDate() + 1)
        // }

        const date = Moment(startDate).utc()
        const end = Moment(endDate).utc()

        const dates = []
        while (date.isSameOrBefore(end)) {
          dates.push(date.format('YYYY-MM-DD'))
          date.add(1, 'days').format('YYYY-MM-DD')
        }

        return dates
      }

      const d1 = new Date(props.collaboration.start_at)
      const d2 = new Date(props.collaboration.end_at)

      const days = getDatesInRange(d1, d2).filter((date) => {
        // const dateMoment = Moment(date)
        const newDate = Moment(date).utc()
        const today = Moment(Moment().utc().format('YYYY-MM-DD'))
        // const startAt = Moment.utc(props.collaboration.start_at).format('YYYY-MM-DD')
        const endAt = Moment.utc(props.collaboration.end_at).format('YYYY-MM-DD')
        // const allDays = props.collaboration?.allowed_week_days?.length > 6
        // const isAfter = newDate.isAfter(startAt)
        // const isBefore = Moment(newDate).isBefore(endAt)
        // const isBetween = newDate.isBetween(startAt, endAt)
        // const isSameStart = newDate.isSame(startAt)
        // const isSameEnd = newDate.isSame(endAt)
        const weekDay = Moment(date).isoWeekday()
        const isWeekDay = props.collaboration?.allowed_week_days.includes(weekDay)

        // const isSameAfter = newDate.isSameOrAfter(startAt)
        // const isSameBefore = newDate.isSameOrBefore(endAt)
        // console.log(isSameAfter, isSameBefore, isWeekDay, newDate.utc().format('YYYY-MM-DD'), date)
        //
        // if ((isSameAfter || isSameBefore) && isWeekDay) {
        //   return newDate
        // }

        if (newDate.isSameOrAfter(today) && newDate.isSameOrBefore(endAt)) {
          if (isWeekDay) {
            return newDate
          }
        }
      })
      return days.map((date) => {
        // console.log(Moment(date).format('YYYY-MM-DD'))
        return {
          label: Moment(date).format('ddd, DD MMM'),
          value: Moment(date).format('YYYY-MM-DD')
        }
      })
    })

    const filterAvailableDates = computed(() => {
      return props.availableDates.map((date) => {
        return {
          label: Moment(date).format('ddd, DD MMM'),
          value: date
        }
      })
    })

    const checkReserve = (fromCard) => {
      bookingFail.value = false
      loadingCheck.value = !fromCard
      emit('loading', true)
      emit('booking-modal', false)

      bookingFail.value = false
      const form = {
        date: date.value + ' ' + selectedHour.value,
        people_amount: peopleAmount.value,
        time: selectedHour.value,
        zone: null
      }
      emit('invalid-date', false)
      emit('validated-date', form)
      if (fromCard) {
        emit('booking-modal', true)
      }

      loadingCheck.value = false
      emit('loading', false)
    }

    const getAvailableDates = () => {
      loading.value = true
      selectedHour.value = null
      date.value = ''
      emit('validated-date', { form: { date: ' ', zone: '' } })
      booking.availableDates({ restaurant_id: props?.restaurant?.id, people_count: peopleAmount.value ? parseInt(peopleAmount.value) : parseInt(props?.collaboration?.companions_limit + 1) })
        .then(({ data: response }) => {
          availableDatesList.value = response?.available_dates
          validDate.value = !!availableDatesList.value.length
          if (response?.available_dates?.length > 0) {
            if (validDate.value && filteredDates.value.length > 0) {
              haveAvailableDates.value = true
              emit('valid-dates', true)
            } else {
              haveAvailableDates.value = false
              emit('valid-dates', false)
            }
          } else {
            haveAvailableDates.value = false
          }
        })
        .catch(({ response }) => {
          emit('error-code', response?.data?.error_code)
        })
        .finally(() => (loading.value = false))
    }

    const dateAvailability = () => {
      loading.value = true
      selectedHour.value = null
      zone.value = false
      emit('loading', true)

      const body = {
        restaurant_id: props.restaurant.id,
        date: date.value,
        people_count: parseInt(peopleAmount.value)
      }
      bookingFail.value = false
      booking.dateAvailability(body)
        .then(({ data: response }) => {
          providerZones.value = response?.available_hours_per_zone
          // hoursList.value = Object.values(providerZones.value)[0]
          const haveZones = response.available_hours_per_zone
          if (haveZones.length === 0) {
            bookingFail.value = true
            failProviderZones.value = true
            emit('loading', false)
            emit('invalid-date', true)
            loading.value = false
          } else {
            setHour({
              zoneHour: props.collaboration?.booking?.time ? props.collaboration?.booking?.time : providerZones.value[Object.keys(providerZones.value)[0]][0],
              zoneName: Object.keys(providerZones.value)[0]
            })
            loading.value = false
            bookingFail.value = false
            failProviderZones.value = false
            emit('loading', false)
            emit('invalid-date', false)
            emit('validated-date', true)
          }
        })
        .catch(() => {
          emit('loading', false)
          emit('invalid-date', true)
          loading.value = false
        })
      // .finally(() => (loadingBooking.value = false))
    }
    const setHour = ({
      zoneHour,
      zoneName,
      fromCard
    }) => {
      selectedHour.value = zoneHour
      zone.value = zoneName
      if (zoneHour !== ' ' && name !== ' ') {
        checkReserve(fromCard)
      }
    }
    const hoursList = computed(() => {
      const hours = []
      for (let x = 12; x < 24; x++) {
        const $0 = x < 10 ? '0' + x + ':' + '00' : x + ':' + '00'
        const $1 = x < 10 ? '0' + x + ':' + '30' : x + ':' + '30'
        hours.push($0.toString())
        hours.push($1.toString())
      }
      return hours
    })

    const handleAvailabilities = () => {
      // if (date.value !== ' ') {
      //   getAvailableDates()
      // } else {
      //   dateAvailability()
      // }
      console.log('hadle from booking uncover')
    }
    onMounted(() => {
      peopleAmount.value = !props.soloLink ? props.collaboration.companions_limit + 1 : 2
      // getAvailableDates()
      if (props.collaboration?.booking?.status === 'active') {
        date.value = props.collaboration?.booking?.date
        selectedHour.value = props.collaboration?.booking?.time
        peopleAmount.value = props.collaboration?.booking?.people_amount
        // dateAvailability()
      }
    })
    return {
      date,
      peopleAmount,
      booking,
      zone,
      selectedHour,
      providerZones,
      bookingHours,
      loading,
      loadingCheck,
      bookingFail,
      filteredDates,
      filterAvailableDates,
      hoursList,
      matches,
      failProviderZones,
      confirmModal,
      haveAvailableDates,
      bookingFailError,
      checkReserve,
      dateAvailability,
      setHour,
      getAvailableDates,
      handleAvailabilities
    }
  }
}
</script>

<style scoped>

</style>
