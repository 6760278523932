<template>
  <aside class="bg-black bg-opacity-50 inset-0 z-80" style="margin: 0;">

    <transition name="fade">
      <div @click="$emit('update:show', !show)" class="fixed inset-0 bg-black z-80 bg-opacity-50" v-if="show" />
    </transition>

    <div id="booking-card" class="flex flex-col fixed w-full bg-white left-0 z-80 inset-y-0 transition-transform transform md:w-booking md:min-w-booking" :class="{
          '-translate-x-full': !show,
          'translate-x-0': show
        }">
      <div class="flex flex-col fixed bg-white w-full left-0 z-80 inset-y-0 transition-transform transform overflow-y-scroll">
        <header class="text-left flex items-center justify-center font-bold py-4">
          <div class="flex-1">
            <button @click="$emit('update:show', false)" class="text-left select-none items-center font-bold text-lg justify-start text-black">
              <i class="icon-arrowLeft space-x-2 py-4 px-4" style="font-weight: bold !important"/>
            </button>
          </div>
          <div class="flex-1 text-center text-lg">
            <p>{{ restaurant?.name }}</p>
          </div>
          <div class="flex-1" />
        </header>
        <div v-if="restaurant">
          <div v-if="restaurant.covermanager_id" class="p-4">
            <Booking
              :collaboration="collaboration"
              :restaurant="restaurant"
              :available-dates="availableDates"
              :solo-link="soloLink"
              @loading="loading = $event"
              @booking-modal="showBookingModal = $event"
              @validated-date="$emit('validated-date', $event)"
              @invalid-date="invalidDate = $event"
              @valid-dates="$emit('valid-dates', $event)"
              @error-code="$emit('error-code', $event)"
            />
          </div>
          <div v-else-if="restaurant.covermanager_id === null && !soloLink" class="p-4">
            <booking-uncover
              v-if="!soloLink"
              :collaboration="collaboration"
              :restaurant="restaurant"
              :available-dates="availableDates"
              :solo-link="soloLink"
              @loading="loading = $event"
              @booking-modal="showBookingModal = $event"
              @validated-date="$emit('validated-date', $event)"
              @invalid-date="invalidDate = $event"
              @valid-dates="$emit('valid-dates', $event)"
              @error-code="$emit('error-code', $event)" />
          </div>
        </div>
        <div class="px-4 pb-20">
<!--          <p class="text-left text-sm">Los plazos de reserva se proporcionan en asociación con</p>-->
<!--          <p v-if="!restaurant.covermanager_id && !soloLink" class="text-left text-sm">IMPORTANTE: <b class="text-primary">{{ restaurant.name }}</b> no ha conectado reservas, avanzar por otra vía (Google, Web, Teléfono) indicando tu código de reserva <b class="text-primary">{{ collaboration.reservation_code }}</b> y nos dejáis saber pinchando en ¿Cuando es tu Reserva?</p>-->
<!--          <div class="align-middle justify-center text-left mt-3">-->
<!--            <img src="https://lh3.googleusercontent.com/D7PxbVLdrqkoWSupLh5MJBMTpQ386REzJgj0V7-tanXddX1lfMG-My_n7sybImiS" class="inline-block w-5 h-5">-->
<!--            <p class="inline-block text-left ml-2">CoverManager</p>-->
<!--          </div>-->
        </div>
      </div>
      <div class="fixed w-full bottom-0 border-t-2 py-3 bg-white z-80">
        <button
          class="block mx-auto rounded-md w-11/12 bg-primary p-2 text-white disabled:text-dgray disabled:bg-gray"
          @click="showBookingModal = true">
          <a-loader v-if="loading" class="h-6 w-6 block mx-auto" :color="'text-white'" />
          <span v-else>Continuar</span>
        </button>
      </div>
    </div>
  </aside>
  <confirm-modal
    v-model:show-modal="showBookingModal"
    v-model:first_name="client.first_name"
    v-model:last_name="client.last_name"
    v-model:email="client.email"
    v-model:phone="client.phone"
    :collaboration="collaboration"
    :restaurant="restaurant"
    :reservation-form="reservationForm"
    :loading="loading"
    :loading-reservation="loadingReservation"
    :completed="completed"
    :solo-link="soloLink"
    @update-comment="$emit('update:comment', $event)"
    @confirm-booking="$emit('confirm-booking', { ...reservationForm, ...client, comment: comment })"/>
</template>

<script>
import { onMounted, ref } from 'vue'
import Booking from '@/components/booking/booking'
import BookingUncover from '@/components/booking/booking-uncover'
import ConfirmModal from '@/components/booking/confirm-modal'

export default {
  name: 'booking-card',
  components: {
    BookingUncover,
    ConfirmModal,
    Booking
  },
  props: {
    show: { required: false, type: Boolean, default: false },
    comment: { required: false, type: String, default: '' },
    collaboration: { required: false, type: Object },
    reservationForm: { required: false, type: Object },
    availableDates: { required: false, type: Object },
    restaurant: { required: false, type: Object },
    loadingReservation: { required: false, type: Boolean },
    completed: { required: false, type: Boolean },
    soloLink: { required: false, type: Boolean, default: false }
  },
  emits: ['update:show', 'update:comment', 'confirm-booking', 'validated-date', 'valid-dates', 'error-code', 'loading'],
  setup (props) {
    const client = ref({ first_name: '', last_name: '', email: '', phone: '' })
    const showBookingModal = ref(false)
    const loading = ref(false)
    const invalidDate = ref(false)

    onMounted(() => {
      showBookingModal.value = false
    })
    return {
      client,
      showBookingModal,
      loading,
      invalidDate
    }
  }
}
</script>

<style scoped>
#booking-card {
 padding-bottom: 63px;
}
</style>
