<template>
  <div class="space-y-2">

    <h1 class="text-default-xl text-black">
      ¿Quieres colaborar con nosotros?
    </h1>

    <!-- v-if="collaboration?.restaurant?.covermanager_id || collaboration.type === 'public'" -->
    <button
      :disabled="loading || requesting"
      @click="accept"
      v-if="collaboration.type === 'public' && collaboration.uses !== collaboration.uses_limit"
      class="bg-primary focus:outline-none flex justify-center font-normal text-white w-full p-3 rounded-full disabled:bg-gray">
      <a-loader v-if="loading || requesting" color="text-white" class="h-6 w-6" />
      <template v-else>
        Solicitar colaboración
      </template>
    </button>

    <!-- <button
      v-else
      :disabled="loading || requesting"
      @click="accept"
      class="bg-white focus:outline-none flex justify-center font-normal border border-primary text-primary w-full p-3 rounded-full disabled:bg-gray">
        <a-loader v-if="loading || requesting" color="text-white" class="h-6 w-6" />
      <template v-else>
        Ya tengo reserva
      </template>
    </button> -->

    <p v-if="collaboration.uses !== collaboration.uses_limit" class="text-center pt-4 text-default-xs text-green-400">
      ¡Quedan {{ collaboration.uses_limit - collaboration.uses }} plazas disponibles!
    </p>
    <p v-else class="text-center pt-4 text-default-xs text-red-400">No quedan plazas disponibles.</p>
  </div>

  <a-alert
    :show="modals.accepted"
    hide-cancel
    disabled-overlay-dismiss
    @confirm="acceptRedirect"
  >
    <div class="space-y-3 text-center">
      <h1 class="text-lg text-gray-800">
        ¡Enhorabuena!
      </h1>
      <h1 class="text-lg">
        Has solicitado participar en esta colaboración. Debes esperar la aprobación del restaurante.
      </h1>
    </div>
  </a-alert>

  <a-alert
    title="Límite alcanzado"
    :show="failed"
    @cancel="failed = false"
    @confirm="failed = false">
    <h1 class="text-lg">
      Has llegado a tu máximo de colaboraciones activas. <br>Publica y valora antes de aceptar nuevas colaboraciones
    </h1>
  </a-alert>
  <a-alert
    :show="modals.followers"
    hide-cancel
    @confirm="modals.followers = false">
    <h1 class="text-lg mt-2">
      No posee la cantidad requerida de seguidores para aplicar a esta colaboración
    </h1>
  </a-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  data: () => ({
    modals: {
      accepted: false,
      followers: false
    },
    requesting: false,
    failed: false,
    response: {}
  }),
  computed: mapState({
    user: ({ session }) => session.user
  }),
  methods: {
    acceptRedirect () {
      this.$emit('action-accept')
    },
    redirectIgnore () {
      this.$emit('action-ignore')
    },
    accept () {
      this.requesting = !this.requesting
      this.$repository.collaborations
        .foodie(this.user.foodie.id)
        .accept(this.collaboration.id)
        .then(({ data }) => {
          this.response = {
            reservation_code: data.reservation_code
          }
          this.modals.accepted = true
        })
        .catch(err => {
          this.failed = err.response?.data?.message === 'foodie_collab_limit_reached'
          this.modals.followers = err.response?.data?.message === 'min_ig_followers_required'
        })
        .finally(() => (this.requesting = !this.requesting))
    }
  }
}
</script>
