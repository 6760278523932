<template>
  <p class="text-gray-400 text-center text-default-lg">
    Colaboración cancelada
  </p>

  <a-alert
    :show="shouldShowModal" hide-cancel @confirm="toggleShow" title="Colaboración cancelada">
    Por favor cancelar la reserva realizada el día {{collaboration?.booking?.date}} por medio de la confirmación recibida por correo
  </a-alert>
</template>

<script>
import { defineComponent, computed, toRefs, ref } from 'vue'

export default defineComponent({
  props: {
    collaboration: { required: false, default: { booking: null } },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  setup (props) {
    const refs = toRefs(props)
    const show = ref(refs.collaboration.value.booking !== null)
    const shouldShowModal = computed(() => {
      // return show.value && refs.collaboration.value.status !== 'closed'
      if (refs?.collaboration?.value?.restaurant?.covermanager_id === null) {
        return show.value && refs.collaboration.value.status !== 'close'
      } else {
        return false
      }
    })

    const toggleShow = () => {
      show.value = !show.value
    }

    return {
      shouldShowModal,
      toggleShow
    }
  }
})
</script>
