<template>
  <div class="flex-grow flex lg:bottom-2 bottom-14 w-full max-w-5xl mx-auto items-end justify-center py-3 px-4">
    <h1 class="text-center text-lg text-gray">Esperando confirmación del restaurante</h1>
  </div>
</template>

<script>
export default {
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  }
}
</script>
