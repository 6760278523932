<template>
  <button
    class="text-center focus:outline-none font-light text-sm text-gray-400"
    @click="show = true">
    Quiero cancelar la colaboración
  </button>
  <a-alert
    :show="show"
    :disabled-confirm="loading"
    @cancel="show = false"
    @confirm="cancel">
    <div class="space-y-2">
      <h1 class="text-black text-lg">
        Vas cancelar esta colaboración
      </h1>
      <h2 class="text-gray-400">
        ¿Estás seguro de querer continuar?
      </h2>
      <h2 class="text-gray-400">
        En caso de haber realizado una reserva. Por favor recuerde cancelar.
      </h2>
    </div>

    <template #confirm v-if="loading">
      <a-loader class="w-5 h-5" color="text-primary" />
    </template>
  </a-alert>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRepository, useSession } from '../../../../../../hooks'
import { useRoute } from 'vue-router'

export default defineComponent({
  emits: ['cancel'],
  setup (_, { emit }) {
    const show = ref(false)
    const loading = ref(false)
    const { user } = useSession()
    const repository = useRepository(({ collaborations }) => collaborations.foodie(user.foodie.id))
    const { params } = useRoute()

    const cancel = () => {
      loading.value = !loading.value
      repository.cancel(params.id)
        .then(() => {
          show.value = false
          emit('cancel')
        })
    }

    return {
      show,
      cancel,
      loading
    }
  }
})
</script>
