<template>
  <p class="text-gray-400 text-center text-default-lg">
    Colaboración Ignorada
  </p>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'expired-action',
  props: {
    collaboration: { required: false, default: { booking: null } },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  }
})
</script>
