<template>
  <div class="space-y-2">
    <div v-if="loadingBooking" class="w-full">
      <a-loader class="h-8 w-8 block mx-auto" :color="'text-gray'" />
    </div>
    <div v-else>
      <div v-if="collaboration?.restaurant?.covermanager_id">
        <h3 class="font-bold mt-3 mb-1 text-onboarding_gray text-lg">¿Qué día quieres venir?</h3>
        <div v-if="errorCode !== ''">
          <!--        <p class="text-red-400">En este momento el restaurante no puede recibir reservas, intente más tarde</p>-->
          <p class="text-red-400">Este restaurante no está disponible para realizar reservaciones.</p>
        </div>
        <button
          v-else
          class="bg-primary focus:outline-none flex justify-center text-white w-full p-3 rounded-full disabled:bg-gray md:w-2/5 mx-auto"
          @click="showReserveModal = true">
          <a-loader v-if="storing" color="text-white" class="h-6 w-6" />
          <template v-else>
            <span class="items-center">
              <i class="icon-calendar mr-1"></i>
              <span>Reservar una mesa</span>
            </span>
          </template>
        </button>
      </div>
      <div v-else>
        <button
            class="bg-white border border-primary focus:outline-none flex justify-center text-primary mb-small w-full p-3 rounded-full disabled:bg-gray"
            @click="showReserveModal = true">
          <a-loader v-if="storing" color="text-white" class="h-6 w-6" />
          <template v-else>
            ¿Cuando es tu reserva?
          </template>
        </button>
        <p class="my-4" v-if="!collaboration.restaurant.covermanager_id">IMPORTANTE: <b class="text-primary">{{ collaboration.restaurant.name }}</b> no ha conectado reservas, avanzar por otra vía (Google, Web, Teléfono) indicando tu código de reserva <b class="text-primary">{{ collaboration.reservation_code }}</b> y nos dejáis saber pinchando en ¿Cuando es tu Reserva?</p>
<!--        <h3 class="font-bold mt-3 mb-1 text-onboarding_gray text-lg">¿Qué día quieres venir?</h3>-->
<!--        <button-->
<!--          class="bg-primary focus:outline-none flex justify-center text-white w-full p-3 rounded-full disabled:bg-gray md:w-2/5 mx-auto"-->
<!--          @click="showReserveModal = true">-->
<!--          <a-loader v-if="storing" color="text-white" class="h-6 w-6" />-->
<!--          <template v-else>-->
<!--            <span class="items-center">-->
<!--              <i class="icon-phone mr-1"></i>-->
<!--              <span>Reservar una mesa</span>-->
<!--            </span>-->
<!--          </template>-->
<!--        </button>-->
<!--        <button-->
<!--            class="bg-white border mt-2 border-onboarding_gray focus:outline-none flex justify-center text-onboarding_gray w-full p-3 rounded-full disabled:bg-gray md:w-2/5 mx-auto">-->
<!--          <a-loader v-if="storing" color="text-white" class="h-6 w-6" />-->
<!--          <template v-else>-->
<!--              <span>He llamado para reservar</span>-->
<!--          </template>-->
<!--        </button>-->
<!--        <p class="my-4" v-if="!collaboration.restaurant.covermanager_id">IMPORTANTE: <b class="text-primary">{{ collaboration.restaurant.name }}</b> no ha conectado reservas, avanzar por otra vía (Google, Web, Teléfono) indicando tu código de reserva <b class="text-primary">{{ collaboration.reservation_code }}</b> y nos dejáis saber pinchando en ¿Cuando es tu Reserva?</p>-->
      </div>
    </div>
  </div>
  <Booking
    v-model:show="showReserveModal"
    v-model:comment="comment"
    :loading-reservation="loadingBooking"
    :available-dates="availableDatesList"
    :collaboration="collaboration"
    :restaurant="collaboration.restaurant"
    :reservation-form="{
        date: bookingFormValues.date,
        comment: comment,
        use_booking_provider: bookingProvider,
        people_amount: bookingFormValues.people_amount,
        time: bookingFormValues.time,
        zone: bookingFormValues.zone
      }"
    :completed="reservedComplete"
    @loading="loadingBooking = $event"
    @validated-date="setReservationValues"
    @confirm-booking="reserve"
    @valid-dates="validDate = $event"
    @error-code="errorCode = $event"
    />
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido crear la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="$emit('action-book')"
    :show="reserveSuccess">
    <div v-if="collaboration?.restaurant?.covermanager_id">
      <h2 class="">
        ¡Enhorabuena!
      </h2>
      <p class="text-lg">
        Se ha creado su reserva satisfactoriamente
      </p>
    </div>
    <div v-else>
      <h2 class="">
        ¡Gracias!
      </h2>
      <p class="text-lg">
        Hemos tomado nota de la fecha de tu visita.
      </p>
    </div>
  </a-alert>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useSession, useRepository, useErrors } from '@/hooks'
import Moment from 'moment'
import Booking from '@/components/booking/booking-card'

export default {
  name: 'accepted-action',
  components: { Booking },
  props: {
    collaboration: { required: false, type: Object, default: null },
    loading: { required: false, type: Boolean, default: true },
    status: { required: false, type: String, default: null }
  },
  emits: ['action-accept', 'action-ignore', 'action-request', 'action-book', 'action-book-edit', 'action-rate'],
  setup (props) {
    const { user } = useSession()
    const showReserveModal = ref(false)
    const repository = useRepository(({ bookings }) => bookings.foodies(user.foodie.id))
    const booking = useRepository(({ bookings }) => bookings)
    const storing = ref(false)
    const loadingBooking = ref(false)
    const loadingBookingComponent = ref(false)
    // const $filters = inject('$filters', {})
    const bookingProvider = ref(false)
    const bookingFail = ref(false)
    const checkBookingFail = ref(false)
    const peopleAmount = ref(2)
    const people = ref(0)
    const bookingHours = ref([])
    const date = ref('')
    const comment = ref('')
    const selectedHours = ref('empty')
    const selectedMinutes = ref('00')
    const reserveError = ref(false)
    const reserveErrorMessage = ref('')
    const reserveSuccess = ref(false)
    const availableDatesList = ref([])
    const validDate = ref(false)
    const providerZones = ref([])
    const zone = ref('')
    const zoneTime = ref('')
    const bookingValues = ref(false)
    const bookingFormValues = ref({})
    const invalidBookingDate = ref(false)
    const errorCode = ref('')
    const reservedComplete = ref(false)

    const reserve = () => {
      loadingBooking.value = true
      storing.value = !storing.value
      bookingProvider.value = !!props?.collaboration?.restaurant?.covermanager_id

      let form
      if (props?.collaboration?.restaurant?.covermanager_id) {
        form = {
          date: bookingFormValues.value.date,
          comment: comment.value,
          use_booking_provider: bookingProvider.value,
          people_amount: parseInt(bookingFormValues.value.people_amount),
          zone: bookingFormValues.value.zone
        }
      } else {
        form = {
          date: bookingFormValues.value.date,
          people_amount: parseInt(peopleAmount.value),
          use_booking_provider: false,
          comment: comment.value
        }
      }

      repository.store({ ...form, collaboration_id: props.collaboration.id })
        .then(() => {
          showReserveModal.value = false
          reserveSuccess.value = true
        })
        .catch(({ response }) => {
          reserveError.value = true
          showReserveModal.value = false
          date.value = ''
          reserveErrorMessage.value = useErrors(response?.data?.error_code)
          // switch (response?.data?.error_code) {
          //   case 'BK001': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK002': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK003': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK004': {
          //     reserveErrorMessage.value = 'Hora no disponible'
          //     break
          //   }
          //   case 'BK005': {
          //     reserveErrorMessage.value = 'No se pudo crear la reserva'
          //     break
          //   }
          //   case 'BK006': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK007': {
          //     reserveErrorMessage.value = 'Ya exite una reserva con este restaurante'
          //     break
          //   }
          //   case 'BK008': {
          //     reserveErrorMessage.value = 'No se puede crear la reserva sin utilizar un proveedor'
          //     break
          //   }
          //   case 'BK009': {
          //     reserveErrorMessage.value = 'Acción no disponible'
          //     break
          //   }
          //   case 'BK010': {
          //     reserveErrorMessage.value = 'Proveedor no disponible'
          //     break
          //   }
          //   case 'BK011': {
          //     reserveErrorMessage.value = 'La reserva no puede ser cancelada'
          //     break
          //   }
          //   case 'BK012': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK013': {
          //     reserveErrorMessage.value = 'La reserva ya está cancelada y no se puede realizar esta acción'
          //     break
          //   }
          //   default: {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          // }
        })
        .finally(() => {
          storing.value = !storing.value
          loadingBooking.value = false
          reservedComplete.value = true
        })
    }

    const getAvailableDates = () => {
      loadingBooking.value = true
      booking.availableDates({ restaurant_id: props?.collaboration?.restaurant?.id, people_count: props?.collaboration?.companions_limit + 1 })
        .then(({ data: response }) => {
          availableDatesList.value = response?.available_dates
          validDate.value = !!availableDatesList.value.length
          providerZones.value = response.available_hours_per_zone
        })
        .catch((err) => (console.log(err)))
        .finally(() => (loadingBooking.value = false))
    }
    const checkReserve = (type) => {
      switch (type) {
        case 'date': {
          break
        }
        case 'people': {
          selectedHours.value = '00:00'
          break
        }
        case 'hours': {
          break
        }
      }
      loadingBooking.value = true
      bookingFail.value = false
      peopleAmount.value = parseInt(peopleAmount.value)

      let peopleAmountFix = peopleAmount.value

      peopleAmountFix = peopleAmountFix + 1

      const body = {
        restaurant_id: props.collaboration.restaurant.id,
        date: date.value + ' ' + selectedHours.value,
        people_amount: peopleAmountFix
      }
      if (zone.value) {
        body.cm_zone = zone.value
      }
      booking.verifyAvailability(body)
        .then(({ data }) => {
          bookingHours.value = data.available_hours
          bookingFail.value = !data.available
          checkBookingFail.value = false
        })
        .catch(({ response }) => {
          checkBookingFail.value = true
          reserveErrorMessage.value = ''
          switch (response?.data?.error_code) {
            case 'BK001': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK002': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK003': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK004': {
              reserveErrorMessage.value = 'Hora no disponible'
              break
            }
            case 'BK005': {
              reserveErrorMessage.value = 'No se pudo crear la reserva'
              break
            }
            case 'BK006': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK007': {
              reserveErrorMessage.value = 'Fecha no disponible, ya existe una reserva con este restaurante'
              break
            }
            case 'BK008': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK009': {
              reserveErrorMessage.value = 'Acción no disponible'
              break
            }
            case 'BK010': {
              reserveErrorMessage.value = 'Proveedor no disponible'
              break
            }
            default: {
              reserveErrorMessage.value = ''
              break
            }
          }
        })
        .finally(() => (loadingBooking.value = false))
    }
    const setReservationValues = (value) => {
      if (value) {
        bookingFormValues.value = (value)
        bookingValues.value = true
      } else {
        bookingValues.value = false
      }
    }
    const changedZone = (value) => {
      zone.value = value
    }
    onMounted(() => {
      reservedComplete.value = false
      if (props?.collaboration?.restaurant?.covermanager_id) {
        if (selectedHours.value === 'empty') {
          // getAvailableDates()
        }
      } else {
        selectedHours.value = '00'
      }
    })

    return {
      date,
      comment,
      storing,
      showReserveModal,
      selectedHours,
      selectedMinutes,
      bookingProvider,
      booking,
      bookingHours,
      bookingFail,
      loadingBooking,
      loadingBookingComponent,
      reserveError,
      reserveErrorMessage,
      reserveSuccess,
      peopleAmount,
      people,
      checkBookingFail,
      availableDatesList,
      validDate,
      providerZones,
      zone,
      zoneTime,
      bookingFormValues,
      bookingValues,
      invalidBookingDate,
      errorCode,
      reservedComplete,
      reserve,
      checkReserve,
      changedZone,
      setReservationValues,
      getAvailableDates
    }
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      today: '',
      startAt: ''
    }
  },
  methods: {
    showModal () {
      this.showReserveModal = true
    }
  },
  mounted () {
    this.today = Moment(new Date().toISOString())
    this.startAt = Moment(this.collaboration.start_at)
  }
}
</script>
<style scoped>

/*label > input[type='radio']:checked {*/
/*    @apply bg-primary text-white border-none*/
/*}*/

input[id^='zoneValue']:checked + label{
  @apply bg-primary text-white border-none
}
</style>
