<template>
  <div class="border rounded-lg w-full p-3 text-black font-medium" v-if="!loading">
    <div class="flex flex-col space-y-0.5 text-md">
      <div class="flex space-x-2 items-center">
        <i class="icon-profile text-xl text-gray-400" />
        <span class="font-semibold">{{ collaboration?.contact_name }}</span>
      </div>

      <div class="flex space-x-2 items-center">
        <i class="icon-phone text-xl text-gray-400" />
        <a :href="`tel:${collaboration?.restaurant?.phone}`" class="text-primary_light font-bold">
          {{ collaboration?.restaurant?.phone }}
        </a>
      </div>

      <div class="flex space-x-2 items-center">
        <i class="icon-pin text-xl text-gray-400" />
        <a :href="`http://maps.google.com/?q=${collaboration?.restaurant?.address}`" target="_blank" class="text-left" rel="noreferer noopener" :class="['font-bold', {
          'text-gray-400 text-sm': !collaboration?.restaurant?.address,
          'text-primary_light': collaboration?.restaurant?.address
        }]">
          <span v-if="collaboration?.restaurant?.address">
            <span>
              {{ collaboration?.restaurant?.address }}
            </span>
            <span v-if="collaboration?.restaurant?.city">
              {{ `, ${collaboration?.restaurant?.zip_code} ${collaboration?.restaurant?.city}` }}
            </span>
            <span v-if="collaboration?.restaurant?.state">
              {{ `, ${collaboration?.restaurant?.state}` }}
            </span>
          </span>
<!--           {{ collaboration?.restaurant?.city ?? ',' + collaboration?.restaurant?.city }} {{ collaboration?.restaurant?.state ?? `, ${collaboration?.restaurant?.state}` }}-->
        </a>
      </div>

      <div v-if="collaboration?.reservation_code && status !== 'pending' && collaboration?.foodies[0]?.pivot?.status !== 'pending_confirmation'" class="flex space-x-2 items-center">
        <i class="icon-bookmark text-xl text-gray-400" />
        <p class="italic text-xs text-gray-400">
          {{ 'Código de reserva: #' + collaboration?.reservation_code }}
        </p>
      </div>

    </div>
  </div>
  <div v-else class="border rounded-lg w-full p-3 text-black animate-pulse">
    <div class="space-y-2">
      <div class="h-4 w-40 bg-gray-300 rounded-full" />
      <div class="h-4 w-32 bg-gray-300 rounded-full" />
      <div class="h-4 w-48 bg-gray-300 rounded-full" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    collaboration: { required: false, type: Object, default: null },
    status: { required: false, type: String, default: null },
    loading: { required: false, type: Boolean, default: false }
  },
  setup () {}
})
</script>
